/********************************************************************

  New Class Instance 
  Plugin.TourGallery
  Return false
  Paramaters false 

*********************************************************************/
;
(function () {

    'use strict';

    var TourGallery = Plugin.TourGallery = (function (id) {

        function TourGallery(id) {
            var scope = this;
            this.galleryID = id;
            this.count = 0;
            this.data = [];
            this.init();
        }

        TourGallery.prototype = {
            init: function () {
                var scope = this;
                var feed = 'https://gomra2015.richmondday.com/api/GetGalleryByPageGroup/' + this.galleryID;
                var titles = {
                    "a8d22947-5a0e-4bf4-8d70-9af92ed4c757": "TOUR GALLERY",
                    "7c7dbbc0-5ee7-47dd-994a-4691da1d5f7d": "VEHICLE GALLERY",
                    "34c78963-c0f0-4992-8340-05c57a347b90": "VEHICLE GALLERY",
                    "a16928b5-cddf-4e3f-bcd6-d674b3d216a9": "VEHICLE GALLERY",
                    "5ae11311-fa76-4cfc-a426-1ae3b171ea1d": "VEHICLE GALLERY",
                    "4d69bbad-1e45-4675-90af-0d76e4da9f30": "VEHICLE GALLERY",
                    "2c768fa3-6007-4cf3-9e4c-29cb7d192593": "VEHICLE GALLERY",
                    "a57980c9-0cba-4426-a7cd-a1d712b951f9": "TOUR GALLERY",
                    "bb5e4823-3ced-4bf9-9937-6d38451b57b5": "TOUR GALLERY",
                    "ae26f8b8-5077-48b3-9dc4-1ec033b79159": "TOUR GALLERY",
                    "c5f2b815-1683-42bf-b182-d26eea7c7936": "PHOTO GALLERY",
                    "9e88d96e-12d7-41e5-b5c7-cb33dd81d6a3": "PHOTO GALLERY",
                    "143d389b-4ee5-4bfc-8326-18b38b6fdabd": "PHOTO GALLERY",
                    "84c23af4-e37b-430c-88b8-98801567d425": "PHOTO GALLERY",
                    "c995fece-7994-44ce-adbf-6013f414a2af": "PHOTO GALLERY",
                    "7af71255-79d7-479f-ad20-01297f051715": "PHOTO GALLERY",
                    "190da141-3b86-4867-bf00-189bb19b431a": "PHOTO GALLERY",
                    "60467eae-919a-4ede-9309-f0cddcc6ac3c": "PHOTO GALLERY"
                };
                $.ajax({
                    dataType: 'json',
                    url: feed,
                    success: function (data) {
                        if (titles[scope.galleryID] === "TOUR GALLERY") {
                            data = data.reverse();
                        };
                        scope.data = data
                        console.log(data)
                        $(".tour-gallery-wrapper #title").text(titles[scope.galleryID]);

                        var items = 4;
                        if (data.length < 2) {
                            for (var a = 0; a < data[0].GalleryImages.length; a++) {
                                var la = '<li data-id="' + data[0].GalleryID + '" data-image="'
                                    + a + '" style="background-image:url(https://gomra2015.richmondday.com/'
                                    + data[0].GalleryImages[a].ImagePath.split('~/')[1] + ')">' +
                                    '<div class="block-inner" title="' + data[0].Title + '"></div>' + '</li>';
                                $("#thumbnail-gallery").append(la);
                                $("#single-thumbnail-gallery").append(la);
                            }

                        } else {
                            for (var i = 0; i < data.length; i++) {
                                var images = data[i].GalleryImages;

                                var li = '<li data-id="' + data[i].GalleryID + '" style="background-image:url(https://gomra2015.richmondday.com/' + images[0].ImagePath.split('~/')[1] + ')">' +
                                    '<div class="block-inner"><div class="popover-title" title="' + images[0].Title + '"><h5>' + data[i].Name + '</h5><div class="plus-icon"><h6>+</h6></div></div></div>' +
                                    '</li>';
                                $("#thumbnail-gallery").append(li);
                                $("#single-thumbnail-gallery").append(li);
                            }
                        }

                        const thumbGallerySlider = $('#thumbnail-gallery').lightSlider({
                            item: 4,
                            slideMove: 1,
                            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                            speed: 600,
                            controls: false,
                            pager: false,
                            slideMargin: 10,
                            loop: false,
                            enableTouch: true,
                            enableDrag: true,
                            freeMove: true,
                            auto: false,
                            pause: 6000,
                            pauseOnHover: true,
                            swipeThreshold: 40,
                            responsive: [{
                                breakpoint: 800,
                                settings: {
                                    item: 3,
                                    slideMargin: 6,
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    item: 2,
                                    slideMove: 1
                                }
                            }
                            ],
                            onSliderLoad: function () {
                                $('#image-gallery').removeClass('cS-hidden');
                                var pagerCount = 0;
                                $('.lSPager li').each(function () {
                                    pagerCount++;
                                });
                                if (pagerCount <= 1) {
                                    $('.lSPager').hide();
                                }
                            }
                        });

                        const singleSlider = $('#single-thumbnail-gallery').lightSlider({
                            item: 1,
                            slideMove: 1,
                            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
                            speed: 600,
                            controls: false,
                            slideMargin: 10,
                            loop: false,
                            enableTouch: true,
                            enableDrag: true,
                            freeMove: true,
                            auto: false,
                            pause: 6000,
                            pauseOnHover: true,
                            swipeThreshold: 40,
                            responsive: [{
                                breakpoint: 800,
                                settings: {
                                    item: 1,
                                    slideMove: 1,
                                    slideMargin: 6,
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    item: 1,
                                    slideMove: 1
                                }
                            }
                            ],
                            onSliderLoad: function () {
                                $('#image-gallery').removeClass('cS-hidden');
                                var pagerCount = 0;
                                $('.lSPager li').each(function () {
                                    pagerCount++;
                                });
                                if (pagerCount <= 1) {
                                    $('.lSPager').hide();
                                }
                            }
                        });

                        $(document).on('click', '.tour-gallery-loader .thumbnail-rail .control', function () {
                            const slider = $(this).parents('.tour-gallery-loader').find('#single-thumbnail-gallery').length > 0 ? singleSlider : thumbGallerySlider;
                            if ($(this).hasClass('right')) {
                                slider.goToNextSlide();
                            } else {
                                slider.goToPrevSlide();
                            }
                        });

                        $('#single-thumbnail-gallery li, #thumbnail-gallery li').on('click', function () {
                            var id = $(this).data('id');
                            var imageId = $(this).data('image');
                            if (!imageId) {
                                imageId = false;
                            }
                            var gallery = new Plugin.PopupGallery(id, imageId);
                        });
                    }
                });
            }
        };

        return TourGallery;
    })();

}).call(this);